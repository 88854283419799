.termsAndConditions div {
  margin-bottom: 10px;
}
.tcTitle {
  font-size: 16px;
  font-weight: bold;
}
/* .bottomSpace {
    margin-bottom: 10px;
} */
