.contactUsBody {
  margin-top: 60px;
}

.contactUsContainer {
  padding: 20px;
}

@media (min-width: 820px) {
  .contactUsContainer {
    margin-top: 60px;
    max-width: 820px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* text-align: center; */
  }
}

@media (max-width: 720px) {
  .contactUsBody {
    margin-top: 20px;
  }
}

.contactUsInfo {
  text-align: center;
}

.contactFormDiv {
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.contactFormDiv *:focus {
  outline: 0;
  outline: none;
}

.contactFormDiv *:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: lightgray;
}
