.program-cards-section {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
}

/*
.home-banner-section {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 800px) {
    .program-cards-section {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px!important;
    }
}

@media(max-width: 1400px) {
    .home-banner-section {
        flex-direction: column;
    }
} */

.home-banner-div {
  display: flex;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  /* border: 1px solid blue; */

  justify-content: space-between;
  padding: 30px;
  padding-right: 0px;
}



.home-banner-left {
  width: 33.3333%;
  margin-top: 70px;
}

.home-banner-right {
  margin-top: 70px;
}

@media (max-width: 1280px) {
  .home-banner-div {
    flex-direction: column;
    align-items: center;
  }
  .home-banner-left {
    width: 50%;
  }
}

@media (min-width: 1280px) {
  .home-banner-div {
    /* min-height: calc(100vh - 220px); */
  }
  .home-banner-section {
    min-height: calc(100vh - 100px);
  }
  .home-banner-right {
    justify-content: right !important;
    width: 66%;
  }
  .program-cards-section {
    justify-content: right !important;
  }
}

@media (max-width: 800px) {
  .program-cards-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px !important;
  }
  .home-banner-left {
    width: 100%;
  }

  .expert-button-wrap {
    text-align: center;
    margin-right: 20px;
  }

  .expert-button {
    /* width: unset !important; */
  }
}

.feature-strip {
  height: 50px;
  /* background-color: rgb(63, 112, 209); */
  margin-top: 50px;
  display: flex;
  color: black;
  justify-content: space-around;
  align-items: center;
}
