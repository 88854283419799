.mp_section {
  display: flex;
  padding: 32px 24px;
  /* background-color: #17255e; */
  background-color: #2c3e50;
  gap: 24px;
  color: white;
  border-radius: 24px;
}

.mp_left_panel {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  flex: 3;
}

.mp_header {
  font-family: Manrope, sans-serif;
  font-size: 20px;
  font-weight: 800;
}
.mp_description {
  line-height: 24px;
}

.mp_image {
  width: 78px;
  height: 78px;
  border-radius: 50%;
}

.mp_features {
  display: grid;
  gap: 20px;
  /* background-color: #213675; */
  background-color: #4e5f70;
  padding: 16px 24px;
  border-radius: 16px;
  width: 100%;
}

.mp_feature_item {
  display: flex;
  gap: 8px;
  align-items: start;
  width: 100%;
}

.mp_features_icon {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  color: white;
}

.mp_feature_text {
  font-size: 15px;
  line-height: 20px;
  color: white;
}

.mp_outcomes {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 16px;
  gap: 16px;
  /* background-color: #213675; */
  background-color: #4e5f70;
  border-radius: 8px;
}

.mp_outcome_item {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1 1 0%;
  /* align-items: center; */
  justify-content: center;
  /* background-color: #17255e; */
  background-color: #2c3e50;
  padding: 16px;
  border-radius: 8px;
  color: white;
}

.mp_right_panel {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  gap: 16px;
  flex-direction: column;
  flex: 2;
}

.mp_details_section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mp_details_item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
  gap: 16px;
  padding: 16px;
  text-align: center;
  /* background-color: #213675; */
  background-color: #4e5f70;
  border-radius: 6px;
}

.mp_details_item_icon {
  width: 25px;
  height: 25px;
  /* color: white; */
}
.mp_details_item_content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
}

.mp_details_item_header {
  font-size: 14px;
  line-height: 20px;
}
.mp_details_item_value {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.mp_buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.learn_more_section,
.apply_now_section {
  width: 100%;
  border: 2px solid white;
  padding: 8px 16px;
  color: white;
  height: 56px;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.apply_now_section {
  background-color: gold;
  color: black;
  border: none;
}

@media (min-width: 768px) {
  .mp_features {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .mp_outcomes {
    flex-direction: row;
  }
}

@media (max-width: 1024px) {
  .mp_section {
    flex-direction: column;
  }
}
