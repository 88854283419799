.program-card-section {
  max-width: 351px;
  width: 351px;
  /* height: 539px; */
  background-color: #fff;
  /* border: 1px solid #cbd5e1; */
  border-radius: 10px;
  margin-right: 24px;
  position: relative;
  /* box-shadow: 2px 2px  1px 3px rgba(96,97,112,.16); */
  box-shadow: 1px 2px 15px -5px rgba(0, 0, 0, 0.5);
  padding: 25px 20px;
  /* background-color: #f2f9f2c4; */
}

.live-section {
  position: absolute;
  top: -35px;
  right: 120px;
  z-index: 10;
  background-color: #f2f9f2c4;
  /* background-color: black; */
  color: white;
  padding: 5px 10px;
  color: red;
  font-size: 20px;
  font-weight: bold;
  border-radius: 20px;
}

.div-block-1872 {
  margin-top: 0;
  margin-bottom: 8px;
  display: flex;
}

.eligible-strip {
  margin-bottom: 16px;
  display: flex;
}

.color-strip {
  color: #155e75;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background-color: #cffafe;
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: underline;
  margin-top: 20px;
}

.program-description {
  /* height: 96px; */
  color: #183b56;
  letter-spacing: -0.01em;
  margin-bottom: 26px;
  font-family: "Inter 600", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.program-details-section {
  /* height: 48px; */
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 24px;
  display: flex;
  /* justify-content: ; */
  gap: 15px;
}

.program-details-header {
  color: #64748b;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 4px;
  font-family: "Inter 600", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.program-details-value {
  width: 140px;
  color: #183b56;
  font-family: "Inter 600", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.more-info-section {
  height: 48px;
  align-items: center;
  display: flex;
}

.more-info-text {
  width: 95px;
  color: #183b56;
  margin-left: 4px;
  font-family: "Inter 500", sans-serif;
  font-weight: 500;
  line-height: 24px;
}

.request-call-back-button {
  height: 48px;
  margin-top: 20px;
  background-color: #1565d8;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 24px; */
  padding: 12px 32px;
  text-decoration: none;
  display: flex;
  /* position: absolute; */
  margin-bottom: -45px;
}

.text-block-829.white-text-color {
  color: #fff;
}

.link-block-42 {
  width: 100%;
  margin-bottom: 24px;
  text-decoration: none;
}

.text-block-830 {
  color: #1565d8;
  text-align: center;
  padding-bottom: 0;
  font-family: "Inter 600", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
}

.button-section {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.know-more-button {
  border: 2px solid #1565d8;
  width: 100%;
  flex: 2;
  padding: 5px;
  text-align: center;
  border-radius: 6px;
  font-weight: bold;
  background-color: white;
  color: #1565d8;
  cursor: pointer;
}

.btn-border{
  border: 2px solid #1565d8;
  width: 100%;
  flex: 2;
  padding: 5px;
  text-align: center;
  border-radius: 6px;
  font-weight: bold;
  background-color: white;
  color: #1565d8;
  cursor: pointer;
}

.btn-background {
  width: 100%;
  background-color: #1565d8;
  flex: 3;
  padding: 5px;
  text-align: center;
  color: white;
  border-radius: 6px;
  cursor: pointer;
}

.subjects-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}

.ai-icons {
  justify-content: center;
  gap: 20px;
}
