/**
modal
    modal-dialog
        modal-content
            header
            body
            footer
*/

.view-job-details-modal {
    /* background-color: red; */
}

.view-job-details-modal-dialog{
    /* to control width and height of modal */
    margin-left: auto;
    margin-right: auto;
    display: block;  
    max-width: 1080px;
    height: 100vh;
    /* padding: 20px; */
    border-radius: 10px;
    /* background-color: goldenrod; */
    padding: 0px;
    margin-bottom: 0;
}


.view-job-details-modal-dialog .modal-content {
    /* height: 80%; */
    /* width: 800px; */ /* this width will be insdie modal width */
    display: block;
    border-radius: 10px!important;
    margin-top: 0px;
    padding: 0px;
}

@media (min-height: 800px) {
    .view-job-details-modal {
        overflow-y: hidden;
    }
    .view-job-details-modal-dialog .modal-content {
        margin-top: -50px;
    }
}

.view-job-details-modal-dialog .view-details-modal-body {
    height: calc(100vh - 280px);
    overflow-y: scroll;
    /* border: 1px solid gray; */
}