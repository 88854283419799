.job-card{
    display: flex;
    flex-direction: row;
    max-width: 1080px;

    border: 2px solid #EDEDED;
    border-radius: 3px;
    background: white;
    padding: 15px;
    position: relative;
    line-height: inherit;
}

.job-details {
    flex: 4;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.job-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    margin: 6px 0 5px;
    color: #40454A;
}

.job-location {
    font-weight: 500;
}

.logo-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.job-skills {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.job-skills-item {
    padding: 2px 8px;
    background-color: #BBF1DD;
    /* background-color: rgb(90, 212, 186); */
    border-radius: 6px;
}

.view-button, .edit-button {
    width: 100px;
    margin-bottom: 5px;
    color: white;
    padding: 4px 12px;
    background-color: #02BFA0;
    border: 0;
    border-bottom: 3px solid #029A82;
    border-radius: 4px;
}

.view-button-section {
    display: flex;
    flex-direction: column;
}

.text-ellipsis-2-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  