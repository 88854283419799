/* MyForm.css */

.form-row {
  /* border: 1px solid blue; */
  margin-bottom: 15px;
}

@media (min-width: 876px) {
  /* .modal-dialog {
    max-width: 800px!important;
    margin-right: auto;
    margin-left: auto;
  } */
  .callback-modal {
    max-width: 800px!important;
    margin-right: auto;
    margin-left: auto;
    overflow-y: initial !important;
  }
  .modal-content {
    padding: 20px 0px;
    position: relative;
  }
}

.modal-content {
  border: none !important;
  /* background-color: rgb(221, 223, 247)!important;
  color: black;
  font-weight: bold!important; */
}

@media (min-height: 800px) {
  .callback-section {
    margin-top: 100px;
    padding-bottom: 100px;
    overflow: scroll;
  }
}
